<template>
	<div class="app-container">
		<el-card class="filter-container" shadow="never">
			<div>
				<i class="el-icon-search"></i>
				<span>筛选搜索</span>
				<el-button style="float: right" @click="searchAdminList()" type="primary" size="small">查询结果</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="listQuery" size="small" label-width="140px">
						<el-form-item label="用户账号："><el-input style="width: 203px" v-model="filter[0].val" placeholder="请输入用户名称/关键字"></el-input></el-form-item>
						<el-form-item label="状态：">
							<el-select v-model="filter[1].val" class="input-width" placeholder="启用" clearable>
								<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-card class="operate-container" shadow="never">
			<i class="el-icon-tickets"></i>
			<span>机构列表</span>
			<el-button class="btn-add" @click="addAdmin" size="mini">添加机构账号</el-button>
		</el-card>
		<div class="table-container">
			<el-table ref="adminTable" :data="list" stripe style="width: 100%" @selection-change="handleSelectionChange" v-loading="listLoading" border>
				<el-table-column type="selection" width="60" align="center"></el-table-column>
		
				<el-table-column label="机构名称" fixed align="center">
					<template slot-scope="scope">
						{{ scope.row.name }}
					</template>
				</el-table-column>
				
				<el-table-column label="用户账号" fixed align="center">
					<template slot-scope="scope">
						{{ scope.row.userLoginId }}
					</template>
				</el-table-column>
				
				<el-table-column label="状态" width="140" align="center">
					<template slot-scope="scope">
						<p>
							<el-switch v-model="scope.row.status"  active-text="启用"  inactive-text="禁用" @change="handlePublishStatusChange(scope.row.id, scope.row.status)" :active-value="1" inactive-value="0"  ></el-switch>
						</p>
					</template>
				</el-table-column>
		
				
				<el-table-column label="操作" fixed="right" width="200" align="center">
					<template slot-scope="scope">
						
						<p>
							<el-button size="mini" type="success" @click="editAdmin(scope.$index, scope.row)">编辑</el-button>
						</p>
						
						<p>
							<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
						</p>
			
					</template>
				</el-table-column>
				
			</el-table>
		</div>
	
		<div class="pagination-container">
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				layout="total, sizes,prev, pager, next,jumper"
				:page-size="listQuery.pageSize"
				:page-sizes="[5, 10, 15]"
				:current-page.sync="listQuery.page"
				:total="total"
			></el-pagination>
		</div>

		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%">
			<el-form :model="permission" :rules="rules" ref="permissionFrom" label-width="150px">
				<el-form-item label="机构名称：" prop="name"><el-input style="width: 250px" v-model="permission.name"></el-input></el-form-item>
			</el-form>
			<el-form :model="permission" :rules="rules" ref="permissionFrom" label-width="150px">
				<el-form-item label="用户账号：" prop="userLoginId"><el-input style="width: 250px" v-model="permission.userLoginId" @keyup.native="permission.userLoginId=permission.userLoginId.replace(/[^a-zA-Z0-9]/g,'');"></el-input></el-form-item>
			</el-form>
			<el-form :model="permission" :rules="rules" ref="permissionFrom" label-width="150px">
				<el-form-item label="用户密码：" prop="loginPassword"><el-input type="password" :show-password="true" style="width: 250px" v-model="permission.showPassword"></el-input></el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit('permissionFrom')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
// import { formatDate } from "@/utils/date";
// import {
//   fetchList,
//   deleteAdmin,
//   updateShowStatus
// } from "@/api/system/userManagement";
// import { getAddress } from "@/api/system/userManagement";
import { formatDate } from '@/plugins/date.js';
const defaultListQuery = {
	entity: 'FaExamOrganiza',
	page: 1,
	pageSize: 5
};
const defaultPermission = {
	userLoginId: '',
	groupCode: 'admin',
	status: 1
};
export default {
	name: 'adminList',

	data() {
		var _this = this;
		return {
			dialogVisible: false,
			dialogTitle: '',
			operateType: null,
			listQuery: {
				loginName: null,
				status: 1,
				page: 1,
				pageSize: 10
			},
			statusOptions: [
				{
					label: '启用',
					value: 1
				},
				{
					label: '禁用',
					value: 0
				}
			],
			filter: [
				{
					col: 'userLoginId',
					type: 'like',
					val: ''
				},
				{
					col: 'status',
					type: '=',
					val: ''
				}
			],
			sheng: [],
			shi: [],
			qu: [],
			list: [],
			total: null,
			listLoading: true,
			multipleSelection: [],
			dialogImageUrl: '',
			permission: Object.assign({}, defaultPermission),
			listQuery: Object.assign({}, defaultListQuery),
			rules: {
				userLoginId: [{ required: true, message: '请输入账号', trigger: 'blur' }],
				loginPassword: [{ required: true, message: '请输入密码', trigger: 'blur' }]
			}
		};
	},
	created() {
		this.getList();
	},
	mounted() {
		// this.getsheng();
	},
	filters: {
		formatTime(time) {
			if (time == null || time === '') {
				return 'N/A';
			}
			let date = new Date(time);
			return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
		},
		formatStatus(status) {
			for (let i = 0; i < defaultStatusOptions.length; i++) {
				if (status === defaultStatusOptions[i].value) {
					return defaultStatusOptions[i].label;
				}
			}
		},
		formatReturnAmount(row) {
			return row.productRealPrice * row.productCount;
		}
	},
	methods: {
		postData() {
			console.log(this.listQuery);
		},
		picPreview(url) {
			this.dialogVis = true;
			this.dialogImageUrl = url;
		},
		getList() {
			this.listLoading = true;
			this.listQuery.filter = JSON.stringify(this.filter);
			this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', this.listQuery, this, res => {
				this.listLoading = false;
				this.list = res.rows;
				this.total = res.total;
				this.totalPage = res.total;
			});

		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleDelete(index, row) {
			this.$confirm('是否要删除该用户', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id: row.id,
					entity: defaultListQuery.entity
				};
				this.$comjs.ajax.postAjax('/jqGrid/commonDeleteById', data, this, res => {
					this.$message({
						message: '删除成功',
						type: 'success',
						duration: 1000
					});
					this.getList();
				});

			});
		},
		handleSizeChange(val) {
			this.listQuery.pageNum = 1;
			this.listQuery.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.listQuery.pageNum = val;
			this.getList();
		},
		searchAdminList() {
			this.listQuery.pageNum = 1;
			this.getList();
		},
		// 显示隐藏
		handlePublishStatusChange(id, status) {
			let data = {
				id: id,
				status:status,
				entity: defaultListQuery.entity
			};
			this.$comjs.ajax.postAjax('/jqGrid/changeStatus', data, this, res => {
				this.$message({
					message: status == 1?"禁用成功！":"启用成功！",
					type: 'success',
					duration: 1000
				});
				this.getList();
			});
		},
		addAdmin() {
			this.dialogVisible = true;
			this.dialogTitle = '添加机构账号';
			this.permission = Object.assign({}, defaultPermission);
		},
		editAdmin(index,row){
			let data = {
				id: row.id,
				entity: defaultListQuery.entity
			};
			this.$comjs.ajax.getAjax('/jqGrid/commonFindDetailById', data, this, res => {
				this.permission = res.data;
				this.permission.loginPassword = "";
				this.dialogVisible = true;
				this.dialogTitle = '添加管理员';
			});
		},
		onSubmit(formName) {
			var that = this;
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$confirm('是否提交数据', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						that.$comjs.ajax.postAjax('/fa/system/editOrganiza', this.permission, this, res => {
							this.dialogVisible = false;
							that.$refs[formName].resetFields();
							that.permission = Object.assign({}, defaultPermission);
							that.$message({
								message: '保存成功！',
								type: 'success',
								duration: 1000
							});
							that.getList();
						});
					});
				}
			});
		}
	}
};
</script>
<style scoped>
.app-container {
	padding: 20px;
}

.operate-container {
	margin-top: 20px;
}
.operate-container .btn-add {
	float: right;
}
.batch-operate-container,
.pagination-container {
	display: inline-block;
	margin-top: 20px;
}
.pagination-container {
	float: right;
}
</style>
